import React from "react";

const SendTemplate = () => {
  return <div className="mt-4">
  <div  className="container">
    <div className="row">
        <div className="col-md-4">

        <label>Select template</label>
        <select className="form-control">
            <option>hi</option>
            <option>hello</option>
        </select>
        </div>
        <div className="col-md-8">hi</div>
    </div>

  </div>
  
  
  </div>;
};

export default SendTemplate;
